// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg-icon {
  width: 50px;
  height: 50px;
  fill: red;
}
svg-icon:hover {
  fill: orange;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,YAAA;EACA,SAAA;AACH;AACG;EACE,YAAA;AACL","sourcesContent":["svg-icon {\n   width: 50px;\n   height: 50px;\n   fill: red;\n \n   &:hover {\n     fill: orange;\n   }\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
