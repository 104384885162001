import { Injectable } from '@angular/core';
import { ToastOptions, ToastaService } from 'ngx-toasta';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastaService: ToastaService) { }

  show(title: string, body: string, type: 'info' | 'success' | 'wait' | 'error' | 'warning') {

    let toastOptions: ToastOptions = {
      title: title,
      msg: body,
      showClose: true,
      timeout: 4000,
      theme: 'bootstrap',
    }

    switch (type) {
      case 'info': this.toastaService.info(toastOptions); break;
      case 'success': this.toastaService.success(toastOptions); break;
      case 'wait': this.toastaService.wait(toastOptions); break;
      case 'error': this.toastaService.error(toastOptions); break;
      case 'warning': this.toastaService.warning(toastOptions); break;
    }
  }
}