import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UsuarioLogin } from "src/app/interfaces/usuario-login";
import { SessionStorageService } from "src/app/utils/services/session-storage.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent {
  public usuario: UsuarioLogin;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(
    public router: Router,
    private _sessionStorageService: SessionStorageService,
  ) {

    const user = JSON.parse(this._sessionStorageService.getItem("user"));

    if (user) {
      this.usuario = user;
    }
  }


  logoutFunc() {
    this._sessionStorageService.clear();
    this.router.navigateByUrl('auth/login');
  }
}
