import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { AppService } from './app.service';
import { SessionStorageService } from './utils/services/session-storage.service';
import { AuthService } from './auth/login/authentication/auth.service';
import { UsuarioLogin } from './interfaces/usuario-login';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  usuarioLogado: UsuarioLogin;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private _appService: AppService,
    private _sessionStorageService: SessionStorageService,
    private _authService: AuthService,
  ) {
    try {
      this.usuarioLogado = JSON.parse(_sessionStorageService.getItem('user'));
    } catch (error) {
      sessionStorage.clear();
    }
  }

  ngOnInit(): void {
    this._appService.setUsuarioSubject(this.usuarioLogado);
    this._authService.getUserPermissions();
  }

}
