// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader--text {
  top: 200%;
  left: 0;
  right: 0;
  margin: auto;
}

.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,iBAAA;EACA,4BAAA;EACA,sBAAA;EACA,mCAAA;AAEF;;AACA;EACE;IACE,wBAAA;EAEF;EAAA;IACE,4BAAA;EAEF;EAAA;IACE,4BAAA;EAEF;EAAA;IACE,wBAAA;EAEF;AACF;AAAA;EACE;IACE,kBAAA;EAEF;EAAA;IACE,mBAAA;EAEF;EAAA;IACE,oBAAA;EAEF;EAAA;IACE,qBAAA;EAEF;AACF","sourcesContent":[".loader--text {\n  top: 200%;\n  left: 0;\n  right: 0;\n  margin: auto;\n}\n.loader--text:after {\n  content: \"Loading\";\n  font-weight: bold;\n  animation-name: loading-text;\n  animation-duration: 3s;\n  animation-iteration-count: infinite;\n}\n\n@keyframes loader {\n  15% {\n    transform: translateX(0);\n  }\n  45% {\n    transform: translateX(230px);\n  }\n  65% {\n    transform: translateX(230px);\n  }\n  95% {\n    transform: translateX(0);\n  }\n}\n@keyframes loading-text {\n  0% {\n    content: \"Loading\";\n  }\n  25% {\n    content: \"Loading.\";\n  }\n  50% {\n    content: \"Loading..\";\n  }\n  75% {\n    content: \"Loading...\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
