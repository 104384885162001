import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ContentComponent} from "./shared/components/layout/content/content.component";
import {content} from "./shared/routes/routes";
import {authGuard} from "./auth/login/authentication/guards/auth.guards";
import {LoginComponent} from "./auth/login/login.component";
import {RegisterComponent} from "./auth/register/register.component";
import {ForgetPasswordComponent} from "./auth/forget-password/forget-password.component";
import {AuthModule} from "./auth/auth.module";

const routes: Routes = [
    {
        path: 'auth/login',
        component: LoginComponent
    },

    // {
    //   path: 'auth/register',
    //   component: RegisterComponent
    // },
    // {
    //   path: 'auth/forget-password',
    //   component: ForgetPasswordComponent
    // },
    {
        path: "",
        component: ContentComponent,
        children: content,
        canActivate: [authGuard]
    },
    {
        path: "**",
        redirectTo: "",
    },
];

@NgModule({
    imports: [
        [
            RouterModule.forRoot(routes, {
                anchorScrolling: "enabled",
                scrollPositionRestoration: "enabled",
            }),
        ],
        AuthModule
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
