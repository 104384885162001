import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AddressService } from '../../utils/services/address.service';
import { MessageService } from '../../utils/services/message.service';
import { UtilsService } from '../../utils/services/utils-service';
import { LoginService } from '../login.service';
import handleErrorMessage from '../../utils/services/error-message-handler';
import { Router } from '@angular/router';
import { UsuarioLogin } from 'src/app/interfaces/usuario-login';

@Component({
  selector: 'app-register-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  submitted = false;
  show: boolean = false;
  form: FormGroup
  estados: any[];

  @ViewChild('loginForm', { static: false })
  loginForm: NgForm;

  constructor(
    private _fb: FormBuilder,
    private _utilsService: UtilsService,
    private _addressService: AddressService,
    private _messageService: MessageService,
    private _loginService: LoginService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
    this.estados = this._utilsService.loadBrazilStates();
  }

  showPassword() {
    this.show = !this.show;
  }

  /**
   * Método de inicialização dos formulários;
   */
  public initForm() {
    this.form = this._fb.group({
      nome: ['', [Validators.required, Validators.minLength(3)]],
      numeroDocumento: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      senha: ['', Validators.required],
      ddd: ['', Validators.required],
      telefone: ['', Validators.required],
      endereco: this._fb.group({
        cep: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
        rua: ['', [Validators.required]],
        numero: ['', [Validators.required]],
        complemento: [''],
        cidade: ['', [Validators.required]],
        bairro: ['', [Validators.required]],
        estado: ['', [Validators.required]],
        pais: ['Brasil'],
      })
    });
  }

  /**
   * Função para buscar o endereço a partir do CEP digitado;
   */
  onBlurCep() {
    this._addressService.getAddressByCep(this.enderecoForm.get('cep').value).subscribe(
      (result) => {

        if (result.erro != undefined && result.erro) {
          this._messageService.show('Atenção', 'CEP digitado está inválido ou não foi encontrado.', 'warning');
        } else {
          let state = this.estados.filter(s => s.uf === result.uf)[0];

          this.enderecoForm.get('rua').setValue(result.logradouro);
          this.enderecoForm.get('bairro').setValue(result.bairro);
          this.enderecoForm.get('complemento').setValue(result.complemento);
          this.enderecoForm.get('cidade').setValue(result.localidade);
          this.enderecoForm.get('estado').setValue(state.name);
        }

      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  /**
   * Método para salvar um novo usuário;
   */
  submit() {

    this.loginForm.form.markAllAsTouched();
    this.submitted = true;

    if (this.form.valid) {
      const usuario = this.form.value;
      this.novo(usuario);
    } else {
      this._messageService.show('Atenção', 'Preencha todos os campos.', 'warning');
    }

  }

  /**
  * get do formulário geral;
   */
  get usuarioForm() {
    return this.form.controls;
  }

  /**
   * get do formulário endereço;
   */
  get enderecoForm() {
    return this.usuarioForm.endereco;
  }

  /**
   * Chama API para gravar um novo usuário;
   * @param usuario 
   */
  novo(usuario: UsuarioLogin) {
    this._loginService.novo(usuario).subscribe({
      next: (result) => {
        this._messageService.show('Atenção', 'Registro Salvo com sucesso!', 'success');
        this.submitted = false;
        this._router.navigate(['/auth/login']);
      },
      error: (err) => {
        console.log(err);
        handleErrorMessage(this._messageService, err);
      }
    })
  }
}
