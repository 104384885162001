import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class UtilsService {


  /**
     * Load Brazil state do add on combobox;
     */
  public loadBrazilStates(): any[] {

    // Acre;
    let state: any[];

    state = [{
      id: 1, name: 'Acre', uf: 'AC',
    }, {
      id: 2, name: 'Alagoas', uf: 'AL',
    }, {
      id: 3, name: 'Amapá', uf: 'AP',
    }, {
      id: 4, name: 'Amazonas', uf: 'AM',
    }, {
      id: 5, name: 'Bahia', uf: 'BA',
    }, {
      id: 6, name: 'Ceará', uf: 'CE',
    }, {
      id: 7, name: 'Distrito Federal', uf: 'DF',
    }, {
      id: 8, name: 'Espírito Santo', uf: 'ES',
    }, {
      id: 9, name: 'Goías', uf: 'GO',
    }, {
      id: 10, name: 'Maranhão', uf: 'MA',
    }, {
      id: 11, name: 'Mato Grosso', uf: 'MT',
    }, {
      id: 12, name: 'Mato Grosso do Sul', uf: 'MS',
    }, {
      id: 13, name: 'Minas Gerais', uf: 'MG',
    }, {
      id: 14, name: 'Pará', uf: 'PA',
    }, {
      id: 15, name: 'Paraíba', uf: 'PB',
    }, {
      id: 16, name: 'Paraná', uf: 'PR',
    }, {
      id: 17, name: 'Pernambuco', uf: 'PE',
    }, {
      id: 18, name: 'Piauí', uf: 'PI',
    }, {
      id: 19, name: 'Rio de Janeiro', uf: 'RJ',
    }, {
      id: 20, name: 'Rio Grande do Norte', uf: 'RN',
    }, {
      id: 21, name: 'Rio Grande do Sul', uf: 'RS',
    }, {
      id: 22, name: 'Rondônia', uf: 'RO',
    }, {
      id: 23, name: 'Roraima', uf: 'RR',
    }, {
      id: 24, name: 'Santa Catarina', uf: 'SC',
    }, {
      id: 25, name: 'São Paulo', uf: 'SP',
    }, {
      id: 26, name: 'Sergipe', uf: 'SE',
    }, {
      id: 27, name: 'Tocantins', uf: 'TO'
    }];

    return state;
  }

  public isValidCnpj(cnpj: string): boolean {

    let length: number;
    let numbers;
    let digits: string;
    let sum: number = 0;
    let pos: number;
    let result;

    // return false if blank or incomplete
    if (cnpj == '') return false;
    if (cnpj == null) return false;
    if (!cnpj) return false;

    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.replace('-', '');

    if (cnpj.length != 14) {
      return false;
    }

    // check first digit
    length = cnpj.length - 2
    numbers = cnpj.substring(0, length);
    digits = cnpj.substring(length);
    sum = 0;
    pos = length - 7;
    for (let i: number = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0))
      return false;

    // check second digit
    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (let i: number = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1))
      return false;

    return true;
  }

  /**
   * Valida se o CPF é valido. Deve-se ser informado o cpf sem máscara.
   */
  public isValidCpf(cpf: string) {
    if (cpf == '') return false;
    if (cpf == null) return false;
    if (!cpf) return false;
    cpf = cpf.replace(/[^\d]+/g, '');

    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999')
      return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  }

  /**
   * function to return UF using State Name;
   */
  public getUfByStateName(stateName: string): string {

    let state: any[];

    state = [{
      id: 1, name: 'Acre', uf: 'AC',
    }, {
      id: 2, name: 'Alagoas', uf: 'AL',
    }, {
      id: 3, name: 'Amapá', uf: 'AP',
    }, {
      id: 4, name: 'Amazonas', uf: 'AM',
    }, {
      id: 5, name: 'Bahia', uf: 'BA',
    }, {
      id: 6, name: 'Ceará', uf: 'CE',
    }, {
      id: 7, name: 'Distrito Federal', uf: 'DF',
    }, {
      id: 8, name: 'Espírito Santo', uf: 'ES',
    }, {
      id: 9, name: 'Goías', uf: 'GO',
    }, {
      id: 10, name: 'Maranhão', uf: 'MA',
    }, {
      id: 11, name: 'Mato Grosso', uf: 'MT',
    }, {
      id: 12, name: 'Mato Grosso do Sul', uf: 'MS',
    }, {
      id: 13, name: 'Minas Gerais', uf: 'MG',
    }, {
      id: 14, name: 'Pará', uf: 'PA',
    }, {
      id: 15, name: 'Paraíba', uf: 'PB',
    }, {
      id: 16, name: 'Paraná', uf: 'PR',
    }, {
      id: 17, name: 'Pernambuco', uf: 'PE',
    }, {
      id: 18, name: 'Piauí', uf: 'PI',
    }, {
      id: 19, name: 'Rio de Janeiro', uf: 'RJ',
    }, {
      id: 20, name: 'Rio Grande do Norte', uf: 'RN',
    }, {
      id: 21, name: 'Rio Grande do Sul', uf: 'RS',
    }, {
      id: 22, name: 'Rondônia', uf: 'RO',
    }, {
      id: 23, name: 'Roraima', uf: 'RR',
    }, {
      id: 24, name: 'Santa Catarina', uf: 'SC',
    }, {
      id: 25, name: 'São Paulo', uf: 'SP',
    }, {
      id: 26, name: 'Sergipe', uf: 'SE',
    }, {
      id: 27, name: 'Tocantins', uf: 'TO'
    }];

    let index = state.findIndex(state => state.name === stateName);

    // if exists update org;
    if (index != -1) {
      return state[index].uf;
    }
  }

  /**
   * function to return UF using State Name;
   */
  public getStateNameByUF(UF: string): string {

    let state: any[];

    state = [{
      id: 1, name: 'Acre', uf: 'AC',
    }, {
      id: 2, name: 'Alagoas', uf: 'AL',
    }, {
      id: 3, name: 'Amapá', uf: 'AP',
    }, {
      id: 4, name: 'Amazonas', uf: 'AM',
    }, {
      id: 5, name: 'Bahia', uf: 'BA',
    }, {
      id: 6, name: 'Ceará', uf: 'CE',
    }, {
      id: 7, name: 'Distrito Federal', uf: 'DF',
    }, {
      id: 8, name: 'Espírito Santo', uf: 'ES',
    }, {
      id: 9, name: 'Goías', uf: 'GO',
    }, {
      id: 10, name: 'Maranhão', uf: 'MA',
    }, {
      id: 11, name: 'Mato Grosso', uf: 'MT',
    }, {
      id: 12, name: 'Mato Grosso do Sul', uf: 'MS',
    }, {
      id: 13, name: 'Minas Gerais', uf: 'MG',
    }, {
      id: 14, name: 'Pará', uf: 'PA',
    }, {
      id: 15, name: 'Paraíba', uf: 'PB',
    }, {
      id: 16, name: 'Paraná', uf: 'PR',
    }, {
      id: 17, name: 'Pernambuco', uf: 'PE',
    }, {
      id: 18, name: 'Piauí', uf: 'PI',
    }, {
      id: 19, name: 'Rio de Janeiro', uf: 'RJ',
    }, {
      id: 20, name: 'Rio Grande do Norte', uf: 'RN',
    }, {
      id: 21, name: 'Rio Grande do Sul', uf: 'RS',
    }, {
      id: 22, name: 'Rondônia', uf: 'RO',
    }, {
      id: 23, name: 'Roraima', uf: 'RR',
    }, {
      id: 24, name: 'Santa Catarina', uf: 'SC',
    }, {
      id: 25, name: 'São Paulo', uf: 'SP',
    }, {
      id: 26, name: 'Sergipe', uf: 'SE',
    }, {
      id: 27, name: 'Tocantins', uf: 'TO'
    }];

    let index = state.findIndex(state => state.uf === UF);

    // if exists update org;
    if (index != -1) {
      return state[index].name;
    }
  }

  public formatCnpj(cnpj: string): string {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
  }

  public formatCpf(cpf: string): string {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
  }

}