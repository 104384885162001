import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AppService} from "src/app/app.service";
import {Token} from "src/app/interfaces/token";
import {SessionStorageService} from "src/app/utils/services/session-storage.service";
import {LoginService} from '../login.service';
import {UsuarioLogin} from '../../interfaces/usuario-login';
import {MessageService} from "src/app/utils/services/message.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

    public newUser = false;
    // public user: firebase.User;
    public loginForm: FormGroup;
    public show: boolean = false
    public errorMessage = null;

    public _submitted = false;
    public passwordTextType: boolean;
    private _usuario: UsuarioLogin;
    private _token: Token;

    public preventAbuse = false;

    constructor(
        public router: Router,
        private _serviceStorage: SessionStorageService,
        private _loginService: LoginService,
        private _appService: AppService,
        private _messageService: MessageService
    ) {
    }

    ngOnInit() {
        this.initForm()
    }

    /**
     * Método de inicialização dos formulários;
     */
    public initForm() {
        this.loginForm = new FormGroup({
            email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
            password: new FormControl('', Validators.required)
        });
    }

    /**
     * Função para login no sistema;
     */
    async login() {

        this._submitted = true;
        this.preventAbuse = true;

        // verifica se todos os campos foram preenchidos;
        console.log(this.loginForm.value)
        if (this.loginForm.valid) {

            // pega os valores digitados pelo usuário;
            this._usuario = this.loginForm.value;

            try {
                // tenta fazer o login;
                this._usuario = await this._loginService.login(this._usuario);

                if (this._usuario.jwt) {
                    // se deu sucesso, salva o token na sessão;
                    this._serviceStorage.setItem('token', JSON.stringify(this._usuario.jwt));

                    // grava o usuário na sessão;
                    this._serviceStorage.setItem('user', JSON.stringify(this._usuario));

                    // seta o usuário logado no subject;
                    this._appService.setUsuarioSubject(this._usuario);
                    // this._authService.getUserPermissions();

                    this._submitted = false;
                    this.initForm();

                    this.router.navigate(['/bill-lading/list']);
                }

            } catch (error) {
                console.log(error);
                this.loginForm.get('password').setValue('');
                this._submitted = false;
                this.errorMessage = true;
            }
        }

        this.preventAbuse = false;
    }

    showPassword() {
        this.show = !this.show
    }

    get f() {
        return this.loginForm.controls;
    }
}
