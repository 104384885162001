import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioLogin } from 'src/app/interfaces/usuario-login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private httpClient: HttpClient) { }

  /**
   * save user;
   * @param user
   */
  login(usuario: UsuarioLogin): Promise<UsuarioLogin | undefined> {
    return this.httpClient
      .post<UsuarioLogin>(`${environment.apiURL}/auth/login`, usuario)
      .toPromise();
  }

  /**
   *
   * @param user
   */
  getUserByEmail(email: string) {
    return this.httpClient.get<UsuarioLogin>(`${environment.apiURL}/api/administration/v1/usuarios/email?email=${email}`);
  }

  /**
   * Cadastra um novo usuário;
   * @param usuario 
   * @returns 
   */
  novo(usuario: UsuarioLogin) {
    return this.httpClient.post<UsuarioLogin>(`${environment.apiURL}/auth/register`, usuario)
  }
}
