import { UsuarioDetalhesResolver } from './guards/usuario-detalhes.resolver';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissaoRecursoGuard } from './../utils/permissoes/permissao-recurso.guards';
import { UsuarioAddComponent } from './usuario-add/usuario-add.component';
import { UsuarioDetalhesComponent } from './usuario-detalhes/usuario-detalhes.component';
import { UsuarioListaComponent } from './usuario-lista/usuario-lista.component';
import { UsuarioComponent } from './usuario.component';
import { authGuard } from '../auth/login/authentication/guards/auth.guards';
import { SharedModule } from '../shared/shared.module';
import { UsuarioResolver } from "./guards/usuario.resolver";
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from '../utils/directives/directives.module';

const routes: Routes = [
    { path: '', redirectTo: 'usuarios', pathMatch: 'full' },
    {
        path: '',
        component: UsuarioComponent,
        // canActivate: [authGuard, PermissaoRecursoGuard],
        // data: { permissao: 'ADMINISTRACAO' },
        children: [
            {
                path: '',
                component: UsuarioListaComponent,
                pathMatch: 'full',
                resolve: { usuarios: UsuarioResolver }
            },
            {
                path: 'add',
                component: UsuarioAddComponent,
                pathMatch: 'full'
            },
            {
                path: 'detalhes/:id',
                component: UsuarioDetalhesComponent,
                pathMatch: 'full',
                resolve: { usuario: UsuarioDetalhesResolver }
            }
        ]
    }
];

@NgModule({
    declarations: [
        UsuarioComponent, UsuarioListaComponent, UsuarioAddComponent, UsuarioDetalhesComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        NgbTooltipModule,
        NgSelectModule,
        NgxMaskDirective,
        NgxMaskPipe,
        DirectivesModule
    ],
    providers: [
        UsuarioResolver,
        UsuarioDetalhesResolver,
        provideNgxMask()
    ]
})
export class UsuarioModule {
}
