import {Injectable} from '@angular/core';
import {Pageable} from "../interfaces/Pageable/pageable";
import {UsuarioFiltro} from "../interfaces/usuario-filtro";

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    state = {
        filtro: {} as UsuarioFiltro,
        data: {} as Pageable
    };

    constructor() {
    }

    destroy() {
        this.state = {
            filtro: {} as UsuarioFiltro,
            data: {} as Pageable
        };
    }
}
