import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Pageable } from "../interfaces/Pageable/pageable";
import { UsuarioFiltro } from "../interfaces/usuario-filtro";
import { environment } from "../../environments/environment";
import { UsuarioLogin } from '../interfaces/usuario-login';

@Injectable({
  providedIn: 'root'
})
export class UsuarioHttpService {

  constructor(
    private _http: HttpClient
  ) { }

  getAll(filtro: UsuarioFiltro, page = 0) {
    return this._http.post<Pageable>(`${environment.apiURL}/api/administration/v1/usuarios?page=${page}`, filtro);
  }

  save(usuario: UsuarioLogin) {
    return this._http.post<UsuarioLogin>(`${environment.apiURL}/api/administration/v1/usuarios/register`, usuario);
  }

  update(usuario: UsuarioLogin) {
    return this._http.put<UsuarioLogin>(`${environment.apiURL}/api/administration/v1/usuarios/${usuario.portalClienteLoginId}`, usuario);
  }

  getById(usuarioId: string) {
    return this._http.get<UsuarioLogin>(`${environment.apiURL}/api/administration/v1/usuarios/${usuarioId}`);
  }

  delete(usuarioId: number) {
    return this._http.delete<Boolean>(`${environment.apiURL}/api/administration/v1/usuarios/${usuarioId}`);
  }
}
