import { Permissao } from '../../interfaces/permissao';
import { AuthService } from './../../auth/login/authentication/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissaoRecursoService {

  permissoes: string[];

  constructor(private _authService: AuthService) {
    this.permissoes = this._authService.getPermissoes();
  }

  /**
   * Verifica se existe permissão para entrar na tela;
   * @param component 
   * @returns 
   */
  temPermissao(component: any): boolean {
    return this.verificaPermissao(component?.data?.permissao);
  }

  /**
   * Verifica se a permissão do componente também existe no token do usuário;
   * @param recurso 
   * @returns 
   */
  verificaPermissao(recurso: string) {
    return this.permissoes.some(r => r.toUpperCase().includes(recurso.toUpperCase()));
  }
}
