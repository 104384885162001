import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "",
    redirectTo: 'bill-lading/list',
    pathMatch: "full"
  },
  {
    path: 'bill-lading',
    loadChildren: () => import("../../bill-lading/bill-lading.module").then((m) => m.BillLadingModule),
  },
  {
    path: "usuarios",
    loadChildren: () => import("../../usuario/usuario.module").then((m) => m.UsuarioModule),
  },
  {
    path: "auth",
    loadChildren: () => import("../../auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: 'vgm',
    loadChildren: () => import("../../vgm/vgm.module").then((m) => m.VgmModule),
  },

  {
    path: 'customer',
    loadChildren: () => import("../../customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: 'ocean-vessel',
    loadChildren: () => import("../../ocean-vessel/ocean-vessel.module").then((m) => m.OceanVesselModule),
  },
  {
    path: 'port',
    loadChildren: () => import("../../port/port.module").then((m) => m.PortModule),
  },

];
