import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { UsuarioLogin } from './interfaces/usuario-login';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  usuario$ = new BehaviorSubject<UsuarioLogin>(null);

  constructor() { }

  setUsuarioSubject(usuario: UsuarioLogin) {
    this.usuario$.next(usuario);
  }

  getUsuarioSubject() {
    return this.usuario$.asObservable();
  }

  /**
     * 
     * @param title 
     * @param text 
     * @returns 
     */
  async fireSwalAsync(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncThreeButtons(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncWarning(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
    }
    )

    return result.isConfirmed;
  }
}
