import { Component, OnInit } from '@angular/core';
import { UsuarioService } from "../usuario.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UsuarioHttpService } from "../../http-services/usuario-http.service";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsuarioFiltro } from 'src/app/interfaces/usuario-filtro';
import handleErrorMessage from 'src/app/utils/services/error-message-handler';
import { MessageService } from 'src/app/utils/services/message.service';
import { AppService } from 'src/app/app.service';
import { UsuarioLogin } from 'src/app/interfaces/usuario-login';

@Component({
    selector: 'app-usuario-lista',
    templateUrl: './usuario-lista.component.html',
    styleUrl: './usuario-lista.component.scss'
})
export class UsuarioListaComponent implements OnInit {

    formFiltro: FormGroup;
    public usuarios = [] as UsuarioLogin[];
    public pageIndex = 0;
    public subscription: Subscription;

    constructor(
        public _usuarioService: UsuarioService,
        private _usuarioHttpService: UsuarioHttpService,
        private _activatedRoute: ActivatedRoute,
        private _fb: FormBuilder,
        private _messageService: MessageService,
        private _router: Router,
        private _appService: AppService
    ) {

    }

    ngOnInit(): void {
        this.initFormFiltro();
        this.getAll();
    }

    initFormFiltro() {
        this.formFiltro = this._fb.group({
            numeroDocumento: [null],
            nome: [null],
            email: [null]
        })
    }

    getAll() {
        // get data from resolver;
        this.subscription = this._activatedRoute.data.subscribe(
            (result) => {
                this._usuarioService.state.data = result.usuarios;
                this.pageIndex = this._usuarioService.state.data.number + 1;
                this.usuarios = Object.assign([], this._usuarioService.state.data.content);
            }
        )
    }

    /**
     * Função para filtrar a table;
     */
    filtrar(page = 1) {
        this._usuarioService.state.filtro = this.formFiltro.value;
        this._usuarioHttpService.getAll(this.filtro, page - 1).subscribe({
            next: (result) => {
                this._usuarioService.state.data = result;
                this.pageIndex = this._usuarioService.state.data.number + 1;
                this.usuarios = Object.assign([], this._usuarioService.state.data.content);
            },
            error: (err) => {
                console.log(err);
                handleErrorMessage(this._messageService, err);
            }
        })
    }

    /**
     * Evento da paginação da tela;
     * @param event 
     */
    pageChanged(event: any) {
        this._usuarioService.state.filtro = this.formFiltro.value;
        this._usuarioHttpService.getAll(this.filtro, event - 1).subscribe({
            next: (result) => {
                this._usuarioService.state.data = result;
                this.pageIndex = this._usuarioService.state.data.number + 1;
                this.usuarios = Object.assign([], this._usuarioService.state.data.content);
            },
            error: (err) => {
                console.log(err);
                handleErrorMessage(this._messageService, err);
            }
        })
    }

    novoUsuario() {
        this._router.navigate(["/usuarios/add"]);
    }

    limpaFiltro() {
        this.formFiltro.reset();
    }

    async delete(usuarioId: number) {

        const confirmation = await this._appService.fireSwalAsync('Atenção', 'Deseja Realmente salvar?', 'Salvar');

        if (confirmation) {
            this._usuarioHttpService.delete(usuarioId).subscribe({
                next: (result) => {
                this._messageService.show('Atenção', 'Registro deletado com sucesso!', 'success');
                this.filtrar(this.data.number + 1);
                },
                error: (err) => {
                    console.log(err);
                    handleErrorMessage(this._messageService, err);
                }
            })
        }

    }

    get f() {
        return this.formFiltro.controls;
    }

    get filtro() {
        return this._usuarioService.state.filtro;
    }

    get data() {
        return this._usuarioService.state.data;
    }
}

