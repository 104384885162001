import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Token } from './../../../interfaces/token';
import { NgxPermissionsService } from 'ngx-permissions';
import { SessionStorageService } from '../../../utils/services/session-storage.service';
import { UsuarioLogin } from 'src/app/interfaces/usuario-login';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  _userLogin: UsuarioLogin | null;
  _token = '' as string | null;
  isLoading$: any;

  constructor(
    private _sessionStorageService: SessionStorageService,
    private _permissionsService: NgxPermissionsService
  ) { }

  /**
   * Get token from session;
   */
  getToken() {
    try {
      this._token = JSON.parse(this._sessionStorageService.getItem('token'));
    } catch (error) {
      console.info(error);
      this._token = null;
    }
    if (this._token == null) {
      return '';
    } else {
      return this._token;
    }
  }

  /**
   * Check if user is authenticated;
   */
  public isAuthenticated(): boolean {
    try {
      this._userLogin =JSON.parse(this._sessionStorageService.getItem('user'));
      this._token = JSON.parse(this._sessionStorageService.getItem('token'));
    } catch (error) {
      this._userLogin = null;
    }

    // check if user and token is not null;
    if (this._userLogin != null && this._token != null) {
      // get token informations;
      const tokenInformation = this.getUsuarioId();

      // check if session storage has been modified;
      if (this._userLogin.portalClienteLoginId !== tokenInformation) {
        sessionStorage.clear();
        return false;
      }

      // check if token has experied;
      return this.isTokenExpired(this._token);
    }
    return false;
  }

  /**
   *
   * @param token
   */
  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = helper.getTokenExpirationDate(token);
    if (!date) { return false; }
    return date.valueOf() > new Date().valueOf();
  }

  /**
 * Busca as permissões que estão no token do usuário;
 * @param token 
 * @returns 
 */
  getPermissoes(): any {
    const token = this.getToken();
    if (this.isAuthenticated()) {
      const decodedToken = helper.decodeToken(token);
      return decodedToken.permissoes;
    }
  }

  getUsuarioId(): number {
    const token = this.getToken();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.id;
  }

  getTenantId(): string {
    const token = this.getToken();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.sub;
  }

  getPerfis(): string[] {
    const token = this.getToken();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.roles;
  }

  getUserPermissions() {
    const permissoes = this.getPermissoes();
    if (this.isAuthenticated() && permissoes) {
      try {
        this._permissionsService.loadPermissions(permissoes);
      } catch (err) {
        sessionStorage.clear();
      }
    }
  }

  hasPerfil(recurso: string) {
    return this.getPermissoes().some(p => p.includes(recurso));
  }

  hasPerfilIn(nomePerfis: string[]) {
    for (const valor of this.getPermissoes()) {
      if (nomePerfis.includes(valor)) {
        return true;
      }
    }
    return false;
  }
}
