import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {


  public url: string = 'https://viacep.com.br/ws/${CEP}/json';

  constructor() { }

  /**
   * Função para buscar o endereço pelo CEP;
   * @param cep 
   * @returns 
   */
  getAddressByCep(cep: string): Observable<any> {
    let urlReplaced = this.url.replace("${CEP}", cep);
    return new Observable((x) => {
      var request = new XMLHttpRequest();
      request.open('get', urlReplaced, true);
      request.send();
      request.onload = function () {
        var data = JSON.parse(this.response);
        x.next(data)
      }
    })
  }
}
